<template>
  <div class="flex flex-col h-full w-full">
    <template v-if="!showPhoto">
      <div class="flex flex-col h-[30%]">
        <div class="flex-1 bg-yellow-500"/>
        <div class="flex items-center flex-1 justify-center p-5 bg-blue-700">
          <span class="prose-2xl text-white">Entonces vienes a Colombia?</span>
        </div>
        <div class="flex-1 bg-red-700"/>
      </div>
      <div class="relative w-full h-full">
        <button id="test" class="absolute p-4 bg-red-500 transition-position"
                :style="{top: noPosition.top, left: noPosition.left}"
                @click="getNewRandomPosition('no')"
                @mouseover="getNewRandomPosition('no')">No
        </button>
        <button id="test" class="absolute p-4 bg-amber-500 transition-position"
                :style="{top: maybePosition.top, left: maybePosition.left}"
                @click="getNewRandomPosition('maybe')"
                @mouseover="getNewRandomPosition('maybe')">Tal Vez, aun no preguntè
        </button>
        <button class="absolute top-1/2 left-[70%] bg-green-500 p-10" @click="onConfirm">Claro Amor</button>
      </div>
    </template>
    <div class="flex w-full h-full" v-else>
      <img class="h-full w-full" src="../assets/vale.png" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const noPosition = ref({
  top: '50%',
  left: '30%',
});

const maybePosition = ref({
  top: '50%',
  left: '40%',
});

const showPhoto = ref(false);

function onConfirm() {
  showPhoto.value = true;
}

function getNewRandomPosition(button) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const newTop = Math.random() * (screenHeight - 300) + 'px';
  const newLeft = Math.random() * (screenWidth - 300) + 'px';

  if (button === 'no') {
    noPosition.value.top = newTop;
    noPosition.value.left = newLeft;
  } else {
    maybePosition.value.top = newTop;
    maybePosition.value.left = newLeft;
  }
}
</script>

<style scoped>
.transition-position {
  transition: top 0.5s, left 0.5s;
}
</style>
